.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

/*
31f2f9
fea232
01ed96
*/

#sidebar {
  --bs-dark-rgb: 20, 20, 20;
  --bs-border-color: rgba(30, 30, 30, 0.3);
  --bs-link-color: #28c3c9;
  --bs-link-hover-color: #a6fcff;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  background-image: linear-gradient(to bottom,#31f2f9F0 30%, #fea232ff 100%);
  /*background-image: linear-gradient(to bottom,#01e7e3, #ed0196, #f7a393)*/
}

#sidebar .nav-pills {
  --bs-nav-pills-link-active-bg: #28c3c9;
}

#sidebar .nav-flush .nav-link {
  border-radius: 0;
}

#sidebar > a:first-child svg {
  filter: drop-shadow(1px 1px 8px rgb(255, 255, 255));
  fill: #fea232;
}

#sidebar > a:first-child:hover svg {
  fill: #a6fcff;
}

#sidebar #logo {
  transition: filter 1s ease-in-out;
  filter: drop-shadow(1px 1px 8px rgb(255, 255, 255));
}

#sidebar #logo:hover {
  filter: drop-shadow(1px 1px 4px rgb(255, 255, 255)) brightness(5);
}

#sidebar .dropdown-toggle {
  cursor: pointer;
  transition: color 0.5s ease-in-out;
}

#sidebar .dropdown-toggle span.rounded-circle {
  background-color: var(--bs-link-color);
  color:var(--avgrd-blue);
  padding: 0.25rem 0.5rem;
  transition: filter 0.5s ease-in-out, border-color 0.5s ease-in-out, color 0.5s ease-in-out;
}

#sidebar .dropdown-toggle:hover span.rounded-circle {
  color: var(--bs-link-hover-color);
  filter: drop-shadow(1px 1px 8px rgb(255, 255, 255));
}
