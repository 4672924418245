body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --bs-body-bg:#212529;
  --bs-body-color: #fafafa;
  --bs-link-color: #31f2f9;
  --bs-link-hover-color: #a6fcff;

  --avgrd-blue: #31f2f9;
  --avgrd-orange: #fea232;
  --avgrd-green: #01ed96;

  --avgrd-muted-blue: #05a8ad;

  --bs-code-color: var(--avgrd-orange);

  --bs-btn-bg: var(--avgrd-orange);
}

.btn-avgrd {
  background-color: var(--avgrd-orange) !important;
  border-color: var(--avgrd-orange) !important;
}

.btn-avgrd svg {
  fill: #fff;
  vertical-align: text-bottom;
}

.btn-avgrd:hover {
  background-color: hsl(33, 99%, 70%) !important;
  border-color: var(--avgrd-orange) !important;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.25);
}

#root {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  margin-bottom: 5em;
}

@media (min-width: 768px) {
  header {
    display: flex;
    flex-direction: row;
  }
}

header > nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.col .header {
  display:flex;
  justify-content:space-between;
  align-items:center;

  margin-top:1em;
  margin-bottom:0em;
  padding-bottom:0.5em;

  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-image: linear-gradient(to left, var(--avgrd-blue), var(--avgrd-orange)) 1;
}

main {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main > div.container-fluid {
  padding: 1em 2em;
}

main > div.container {
  padding-top: 1em;
}

table {
  width: 100%;
}

table tbody tr td {
  padding: 18px 12px;
}

table tbody tr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

table thead tr th {
  padding: 16px 12px 8px 12px;
  /*background-color:#28c3c9;*/
  background-color:#25292F;
}

.table-toolbar {
  /*background-color:#28c3c9;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  padding:12px 20px;
  /*border-bottom:1px solid #333;*/
  border-bottom: 1px solid var(--avgrd-muted-blue);
  background-color: #25292F;
}

/* table td:first-child, table th:first-child {
  width: 3em;
} */

tr:nth-child(even) td {
  background-color:#25292F;
}

.table-bottombar {
  /*background-color:#28c3c9;*/
  display: flex;
  justify-content: right;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  padding:12px 20px;
  border-top:1px solid var(--avgrd-muted-blue);
}

.form-check-input:checked[type="checkbox"] {
  background-color: var(--avgrd-orange);
  border-color: var(--avgrd-orange);
}


input[type="text"] {
  background-color:#191919;
  border: 1px solid transparent;
  border-left-color: #555;
  border-left-width: 2px;
  color:#ddd;

  transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus {
  color:#fff;
  background-color:#191919;
  border-color:var(--avgrd-blue);
  box-shadow: 0 0 0 0.25rem rgba(40, 195, 201, 0.25);
}

h1 .text-muted a {
  color: hsl(182, 94%, 35%);
}

header button {
  margin-left: 1em;
  font-family:monospace;
}

button.btn-primary {
  background-color: var(--avgrd-blue);
  color:#333;
  border-color: var(--avgrd-blue);
}

button.btn-primary:hover {
  background-color:hsl(182, 94%, 75%);
  border-color:hsl(182, 94%, 75%);
  color: hsl(182, 94%, 20%);
  transform: translateY(-1px);
}

button.btn-info {
  border-color: var(--avgrd-green);
  background-color: var(--avgrd-green);
  color:#333;
}

button.btn-info:hover {
  border-color: hsl(158, 99%, 80%);
  color: hsl(158, 99%, 20%);
  background-color: hsl(158, 99%, 80%);
  transform: translateY(-1px);
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8 !important;
}

.modal {
  --bs-modal-bg: var(--bs-body-bg) !important;
  --bs-modal-color: var(--bs-body-color) !important;
  --bs-modal-header-border-color: var(--avgrd-blue) !important;
  --bs-modal-footer-border-color: var(--avgrd-blue) !important;
}

.modal-header {
  color: var(--avgrd-blue) !important;
}

table.details tr td:first-child {
  color: var(--bs-gray);
}
