table.events td:nth-child(1) {
    width: 200px;
    font-family: monospace;
}

table.events td:nth-child(2) {
    width: 100px;
}

table.events td:nth-child(3) {
    width: 100px;
}

table.events td:nth-child(4) {
    width: 20%;
}
