.background {
  width:100%;
  height:100%;
  overflow: hidden;
  position:relative;
}

.background > div {
  position: absolute;
  box-shadow: 0px 0px 20px #333333F0;
}


.login {
  position: absolute;
  display: flex;
  align-items: center;
  width: 20%;
  height: 100%;
  left: 15%;

  /* font-family: 'Courier New', Courier, monospace; */
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
}

.login form {
  /* background-image: linear-gradient(to bottom,#01e7e3F0 30%, #111111FF 60%,  #01ed96ff 100%); */
  background-image: linear-gradient(to bottom,#31f2f9F0 30%,  #fea232ff 100%);
  border-radius: 32px;
  box-shadow: 0px 0px 32px #000000E0;
  padding: 40px 0px 10px 0px;
}

.login svg {
  fill: #fea232;
  filter: drop-shadow(0px 0px 28px rgb(255, 255, 255));
  margin:16px 0px;
}

.login form > div {
  padding: 0px;
  margin: 30px 30px;
}

.login label {
  font-size: 1em;
  margin-bottom: 0.2em;
  margin-left: 0.75em;
  color: #f0f6fa;
  display: block;
}

.login h1 {
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
}

.login input[type="text"], .login input[type="password"] {
  font-size: 1.25em;
  background-color: #f0f0f050;
  border-width: 0px;
  outline: 0px;
  border-radius: 20px;
  width: 100%;
  color: #555;
  padding: 12px 16px;
}

.login input[type="text"]:focus-visible, .login input[type="password"]:focus-visible {
  /* outline: 1px solid #01e7e3; */
  outline: 0px;
  box-shadow: 0px 0px 16px #ffffff90;
  background-color:#eee;
  color:#333;
}

.login input[type="submit"] {
  font-size: 1.2em;
  padding: 12px 0px;
  background-color: transparent;
  color: #f0f6fa;
  background-color:#ffffff50;
  border:0px;
  width: 100%;
  border-radius:16px;
  box-shadow: 0px 0px 12px #00000050;
  transition: all 0.3s ease-in-out;
}

.login input[type="submit"]:hover {
  background-color: #ffffffa0;
  color: #fff;
  box-shadow: 0px 0px 12px #eeeeee50;
}

.copy {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 1em;
  color: #eeeeeed0;
  text-shadow: 0px 0px 8px #00000050;
  margin: 0px 16px;
  text-align:right;
}

.copy a {
  color: #eeeeeed0;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}

.copy a:hover {
  color:#eee;
  text-shadow: 0px 0px 8px #eeeeee90;
}
