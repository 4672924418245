.deleted {
    color: #999;
}

.deleted code {
    color: #999;
}

.deleted td {
    text-decoration: line-through;
}

.deleted button {
    visibility: hidden;
}
